import API from '../../services/api'
import { URLS } from '../../environment.js'
import { 
    verifyPermission, 
    permissionsStatus 
} from '../../helpers/permissions'

const getClientCors = async ({ limit = 500, offset = 0 }) => {
    if(!verifyPermission('cors', permissionsStatus.list)) return []
    
    const { data } = await API.get(URLS.cors.list(limit, offset))

    return data
}

const createClientCors = async ({ cors }) => {
    if(!verifyPermission('cors', permissionsStatus.create)) return {}


    const { data: response } = await API.post(URLS.cors.create, cors)

    return response?.data
}

const updateClientCors = async ({ cors, id }) => {
    if(!verifyPermission('cors', permissionsStatus.update)) return {}

    const { data: response } = await API.put(URLS.cors.update(id), cors)

    return response?.data
}

const deleteClientCors = async ({ id }) => {
    if(!verifyPermission('cors', permissionsStatus.delete)) return []

    const { data: response } = await API.delete(URLS.cors.delete(id))

    return response?.data
}

const CORS = {
    getClientCors,
    createClientCors,
    updateClientCors,
    deleteClientCors
}

export default CORS