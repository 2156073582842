import React, { useEffect, useState } from 'react'
import { 
    Typography, 
    Select, 
    Button,
    Row,
    Col,
    Tooltip,
    Form,
    Input,
    Collapse,
    Divider,
    Tabs
} from 'antd'
import { 
    InfoCircleOutlined
} from '@ant-design/icons'
import { useSWRConfig } from 'swr'
import {
    PERMISSIONS
} from '../../environment'
import { 
    Notification
} from '../../components'
import {
    PrincipalContainer
} from '../../containers'
import { Keys } from '../../modules'

const { Item } = Form,
    { Link } = Typography

export default function KeysPage ({ setCurrentPage, userPermissions }) {
    const { cache, mutate } = useSWRConfig()

    const [isLoading, setIsLoading] = useState(true),
        [editData, setEditData] = useState({})

    const { data: keysData, isLoading: keysIsloading } = cache.get('@keys') || { data: [] }

    const handleSave = async () => {
        if(editData?._id) {
            await Keys.updateClientKeys({ keys: editData, id: editData?._id })

            await mutating()

            return Notification({
                title: 'Chaves atualizadas com sucesso!'
            })
        }

        await Keys.createClientKeys({  keys: editData })

        await mutating()

        return Notification({
            title: 'Chaves criadas com sucesso!'
        })
    }

    const mutating = async () => {
        await mutate('@keys', () => Keys.getClientKeys({}))

        const newCache = cache.get('@keys')?.data ?? []

        if(newCache?.length) {
            const key = newCache?.[0] || {}

            setEditData({ ...key })
        }

        setIsLoading(false)
    }

    const handleValue = (e, key, type = null, isDelete = false) => {
        const splitedKeys = key.split('.'),
            hasMoreThanOneKey = splitedKeys.length > 1

        const value = e?.target?.value

        if(hasMoreThanOneKey) {
            let tmpValue = null

            splitedKeys.forEach((key, i) => {
                if(!i) {
                    tmpValue = editData[key]
                } else if (i !== (splitedKeys.length - 1)) {
                    tmpValue = tmpValue[key]
                }
            })

            if(type && !isDelete) {
                const r = new RegExp(`array.string.\*`, 'g')

                if(type.match(r)?.length) {
                    const index = parseInt(type.split('.')[2])
                    let hasIndex = typeof tmpValue[splitedKeys[splitedKeys.length - 1]][index] == 'string'

                    if(!hasIndex) tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                    else {
                        let newValue = hasIndex ? tmpValue[splitedKeys[splitedKeys.length - 1]][index] : ''
                        
                        newValue = !value ? '': value
    
                        tmpValue[splitedKeys[splitedKeys.length - 1]][index] = newValue
                    }
                } else if(!value || isDelete) {
                    tmpValue[splitedKeys[splitedKeys.length - 1]] = []
                } else {
                    tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                }
            } else {
                tmpValue[splitedKeys[splitedKeys.length - 1]] = value
            }
        } else {
            editData[key] = value
        }

        setEditData({ ...editData })
    }

    const mountCollapses = () => {
        const panels = [
            {
                title: 'Email',
                titleDesc: 'Configurações de email',
                tutorialDesc: '',
                children: (env) => {
                    return (
                        <>
                            <Form 
                                layout={'vertical'}
                            >
                                <Row 
                                    gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                    }}
                                >
                                    <Col className="gutter-row" span={8}>
                                        <Item label="Host">
                                            <Input placeholder="Host do servidor" onChange={(e) => handleValue(e, `email.${env}.config.host`)} value={editData.email?.[env].config.host}  />
                                        </Item>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <Item label="Port">
                                            <Input placeholder="Host do servidor" onChange={(e) => handleValue(e, `email.${env}.config.port`)} value={editData.email?.[env].config.port}  />
                                        </Item>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <Item label="TLS">
                                            <Select
                                                value={editData.email?.[env].config.secure}
                                                onChange={(e) => handleValue({ target: { value: e } }, `email.${env}.config.secure`)}
                                                options={[
                                                    {
                                                        value: true,
                                                        label: 'Sim',
                                                    },
                                                    {
                                                        value: false,
                                                        label: 'Não'
                                                    }
                                                ]}
                                            />
                                        </Item>
                                    </Col>
                                </Row>
                                <Divider></Divider>
                                <Row 
                                    gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                    }}
                                >
                                    <Col className="gutter-row" span={24}>
                                        <Item label="De">
                                            <Input placeholder="teste@teste.com.br" onChange={(e) => handleValue(e, `email.${env}.emailParams.from`)} value={editData.email?.[env].emailParams.from}  />
                                        </Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Item label="Usuario">
                                            <Input placeholder="teste@teste.com.br" onChange={(e) => handleValue(e, `email.${env}.auth.user`)} value={editData.email?.[env].auth.user}  />
                                        </Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Item label="Senha">
                                            <Input placeholder="123456" onChange={(e) => handleValue(e, `email.${env}.auth.pass`)} value={editData.email?.[env].auth.pass}  />
                                        </Item>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )
                }
            },
            {
                title: 'Zenvia',
                titleDesc: 'Configurações da Zenvia',
                tutorialDesc: <Typography.Title level={5} style={{ textAlign: 'left', marginTop: 3 }}>
                    Saiba como conseguir o Token
                    <Link target={'_blank'} href="https://zenvia.github.io/zenvia-openapi-spec/v2/#section/Overview/Pre-requisites"> clicando aqui</Link>
                </Typography.Title>,
                children: (env) => {
                    return (
                        <>
                            <Form 
                                layout={'vertical'}
                            >
                                <Row 
                                    gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                    }}
                                >
                                    <Col className="gutter-row" span={24}>
                                        <Item label="Token">
                                            <Input placeholder="Token Zenvia" onChange={(e) => handleValue(e, `zenvia.${env}.auth.token`)} value={editData.zenvia?.[env].auth.token}  />
                                        </Item>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )
                }
            },
            {
                title: 'Slack',
                titleDesc: 'Configurações do Slack',
                tutorialDesc: <Typography.Title level={5} style={{ textAlign: 'left', marginTop: 3 }}>
                    Saiba como conseguir o Token
                    <Link target={'_blank'} href="https://api.slack.com/authentication"> clicando aqui</Link>
                </Typography.Title>,
                children: (env) => {
                    return (
                        <>
                            <Form 
                                layout={'vertical'}
                            >
                                <Row 
                                    gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                    }}
                                >
                                    <Col className="gutter-row" span={24}>
                                        <Item label="Token">
                                            <Input placeholder="Token Slack" onChange={(e) => handleValue(e, `slack.${env}.auth.oauthToken`)} value={editData.slack?.[env].auth.oauthToken}  />
                                        </Item>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )
                }
            },
            {
                title: 'Stripe',
                titleDesc: 'Configurações da Stripe',
                tutorialDesc: <Typography.Title level={5} style={{ textAlign: 'left', marginTop: 3 }}>
                    Saiba como conseguir o Token
                    <Link target={'_blank'} href="https://stripe.com/docs/keys"> clicando aqui</Link>
                </Typography.Title>,
                children: (env) => {
                    return (
                        <>
                            <Form 
                                layout={'vertical'}
                            >
                                <Row 
                                    gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                    }}
                                >
                                    <Col className="gutter-row" span={24}>
                                        <Item label="Secret Key">
                                            <Input placeholder="Secret Key Stripe" onChange={(e) => handleValue(e, `stripe.${env}.auth.secretKey`)} value={editData.stripe?.[env].auth.secretKey}  />
                                        </Item>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )
                }
            },
            {
                title: 'Mercado Pago',
                titleDesc: 'Configurações do Mercado Pago',
                tutorialDesc: <Typography.Title level={5} style={{ textAlign: 'left', marginTop: 3 }}>
                    Saiba como conseguir o Token
                    <Link target={'_blank'} href="https://www.mercadopago.com.br/developers/pt/docs/resources-faqs/credentials"> clicando aqui</Link>
                </Typography.Title>,
                children: (env) => {
                    return (
                        <>
                            <Form 
                                layout={'vertical'}
                            >
                                <Row 
                                    gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                    }}
                                >
                                    <Col className="gutter-row" span={24}>
                                        <Item label="Secret Key">
                                            <Input placeholder="Secret Key Mercado Pago" onChange={(e) => handleValue(e, `mercadoPago.${env}.auth.accessToken`)} value={editData.mercadoPago?.[env].auth.accessToken}  />
                                        </Item>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )
                }
            },
            {
                title: 'AWS',
                titleDesc: 'Configurações do AWS',
                tutorialDesc: <Typography.Title level={5} style={{ textAlign: 'left', marginTop: 3 }}>
                    Saiba como conseguir as credenciais
                    <Link target={'_blank'} href="https://docs.aws.amazon.com/pt_br/cli/latest/userguide/cli-authentication-user.html#cli-authentication-user-get"> clicando aqui</Link>
                </Typography.Title>,
                children: (env) => {
                    return (
                        <>
                            <Form 
                                layout={'vertical'}
                            >
                                <Row 
                                    gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                    }}
                                >
                                    <Col className="gutter-row" span={24}>
                                        <Item label="Região">
                                            <Input placeholder="Região padrão da conta" onChange={(e) => handleValue(e, `aws.${env}.auth.region`)} value={editData.aws?.[env].auth.region}  />
                                        </Item>
                                    </Col>
                                    <Col className="gutter-row" span={24}>
                                        <Item label="Access KEY">
                                            <Input placeholder="Access Key AWS" onChange={(e) => handleValue(e, `aws.${env}.auth.accessKey`)} value={editData.aws?.[env].auth.accessKey}  />
                                        </Item>
                                    </Col>
                                    <Col className="gutter-row" span={24}>
                                        <Item label="Secret Key">
                                            <Input placeholder="Secret Key AWS" onChange={(e) => handleValue(e, `aws.${env}.auth.secretKey`)} value={editData.aws?.[env].auth.secretKey}  />
                                        </Item>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )
                }
            }
        ],
        tabs = [
            {
                title: 'Produção',
                env: 'prd'
            }, 
            {
                title: 'Sandbox',
                env: 'sdb'
            }
        ]
        return panels.map(panel => <Collapse.Panel header={
            <>
                <Typography.Title level={5} style={{ textAlign: 'left', marginTop: 3 }}>
                    {panel.title}
                    <Tooltip placement="topLeft" title={panel.titleDesc}>
                        <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                    </Tooltip>    
                </Typography.Title>
            </>
        }>
            {panel.tutorialDesc}
            <Tabs
                tabPosition={'top'}
                items={tabs.map((tab, i) => {
                    const id = String(i)

                    return {
                        label: tab.title,
                        key: id,
                        children: panel.children(tab.env)
                    }
                })}
            />
        </Collapse.Panel>)
    }

    useEffect(() => {
        const key = keysData?.[0] || {
            'email': {
                'sdb': {
                    'config': {
                        'host': '',
                        'port': '',
                        'secure': false
                    },
                    'auth': {
                        'user': '',
                        'pass': ''
                    },
                    'emailParams': {
                        'from': ''
                    }
                },
                'prd': {
                    'config': {
                        'host': '',
                        'port': '',
                        'secure': false
                    },
                    'auth': {
                        'user': '',
                        'pass': ''
                    },
                    'emailParams': {
                        'from': ''
                    }
                }
            },
            'slack': {
                'sdb': {
                    'auth': {
                        'oauthToken': ''
                    }
                },
                'prd': {
                    'auth': {
                        'oauthToken': ''
                    }   
                }
            },
            'zenvia': {
                'sdb': {
                    'auth': {
                        'token': ''
                    }
                },
                'prd': {
                    'auth': {
                        'token': ''
                    }   
                }
            },
            "stripe" : {
                'sdb': {
                    'auth': {
                        'secretKey': ''
                    }
                },
                'prd': {
                    'auth': {
                        'secretKey': ''
                    }   
                }
            },
            "mercadoPago" : {
                'sdb': {
                    'auth': {
                        'accessToken': '',
                        'webhook': ''
                    }
                },
                'prd': {
                    'auth': {
                        'accessToken': '',
                        'webhook': ''
                    }   
                }
            },
            "aws" : {
                'sdb': {
                    'auth': {
                        'region': '',
                        'accessKey': '',
                        'secretKey': ''
                    }
                },
                'prd': {
                    'auth': {
                        'region': '',
                        'accessKey': '',
                        'secretKey': ''
                    }   
                }
            }
        }

        setEditData({ ...key })

        setIsLoading(false)
    }, [keysIsloading])

    return (
        <>
            <PrincipalContainer menuItemSelected='14' setCurrentPage={setCurrentPage} content={
                <div>
                    <Typography.Title level={2} style={{ textAlign: 'left' }}>
                        Keys 
                        <Tooltip placement="topLeft" title={`As chaves podem ser usadas com a variável "simpleKeys"`}>
                            <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                        </Tooltip>
                    </Typography.Title>
                    {userPermissions?.includes(PERMISSIONS.PERMISSION_LIST) && (
                        <Row>
                            <Col span={24}>
                                <Collapse
                                    accordion={true}
                                    bordered={false}
                                >
                                    {mountCollapses()}
                                </Collapse> 
                                {userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && (
                                    <Button style={{ float: 'right', top: 5 }} type="primary" onClick={handleSave}>Salvar</Button>
                                )}
                            </Col>
                        </Row>
                    )}
                </div>
            } />
        </>
    )
}