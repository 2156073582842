const prefix = '/v1'

const URLS = {
    login: `${prefix}/auth`,
    logout: `${prefix}/auth`,
    refresh: `${prefix}/auth`,
    connections: {
        list: (limit, offset) => `${prefix}/connection/list/${limit}/${offset}`,
        create: `${prefix}/connection`,
        update: (id) => `${prefix}/connection/${id}`,
        delete: (id) => `${prefix}/connection/${id}`,
        test: (id) => `${prefix}/connection/test/${id}`,
        logs: (id, limit, offset) => `${prefix}/connection/${id}/migration-logs/${limit}/${offset}`,
    },
    groups: {
        list: (limit, offset) => `${prefix}/group/list/${limit}/${offset}`,
        create: `${prefix}/group`,
        update: (id) => `${prefix}/group/${id}`,
        delete: (id) => `${prefix}/group/${id}`
    },
    acl: {
        list: (limit, offset) => `${prefix}/acl/list/${limit}/${offset}`,
        create: `${prefix}/acl`,
        update: (id) => `${prefix}/acl/${id}`,
        delete: (id) => `${prefix}/acl/${id}`
    },
    apps: {
        list: (limit, offset, groupId) => `${prefix}/app/list/${limit}/${offset + ((groupId && `?group_id=${groupId}`) || '')}`,
        create: `${prefix}/app`,
        update: (id) => `${prefix}/app/${id}`,
        delete: (id) => `${prefix}/app/${id}`
    },
    billing: {
        list: (limit, offset, year) => `${prefix}/billing/list/${limit}/${offset + ((year && `?year=${year}`) || '')}`
    },
    transactions: {
        list: (limit, offset) => `${prefix}/transactions/list/${limit}/${offset}`
    },
    users: {
        list: (limit, offset) => `${prefix}/users/list/${limit}/${offset}`,
        create: `${prefix}/users`,
        update: (id) => `${prefix}/users/${id}`,
        delete: (id) => `${prefix}/users/${id}`,
        verifyEmail: (email) => `${prefix}/users/${email}/verify`
    },
    server_config: {
        ip: `${prefix}/server/ip`
    },
    credits: {
        show: `${prefix}/credits`
    },
    users_policies: {
        list: (limit, offset) => `${prefix}/users-policies/list/${limit}/${offset}`,
        create: `${prefix}/users-policies`,
        update: (id) => `${prefix}/users-policies/${id}`,
        delete: (id) => `${prefix}/users-policies/${id}`
    },
    updates: {
        list: (token) => `${prefix}/update/verify`
    },
    setup: {
        verifyToken: (token) => `${prefix}/setup/verify/${token}`,
        create: `${prefix}/setup`
    },
    pull_request: {
        list: (limit, offset) => `${prefix}/pull-request/list/${limit}/${offset}`,
        update: (id) => `${prefix}/pull-request/${id}`,
        delete: (id) => `${prefix}/pull-request/${id}`
    },
    migration: {
        list: (limit, offset, connId) => `${prefix}/migration/list/${limit}/${offset + ((connId && `?connection_id=${connId}`) || '')}`,
        create: `${prefix}/migration`,
        update: (id) => `${prefix}/migration/${id}`,
        delete: (id) => `${prefix}/migration/${id}`,
        execute: (id) => `${prefix}/migration/${id}/execute`
    },
    libs: {
        list: (limit, offset) => `${prefix}/libs/list/${limit}/${offset}`,
        create: `${prefix}/libs`,
        update: (id) => `${prefix}/libs/${id}`,
        delete: (id) => `${prefix}/libs/${id}`,
        logs: (id, limit, offset) => `${prefix}/libs/${id}/logs/${limit}/${offset}`,
    },
    keys: {
        list: (limit, offset) => `${prefix}/keys/list/${limit}/${offset}`,
        create: `${prefix}/keys`,
        update: (id) => `${prefix}/keys/${id}`
    },
    workers: {
        list: (limit, offset, groupId) => `${prefix}/workers/list/${limit}/${offset + ((groupId && `?group_id=${groupId}`) || '')}`,
        create: `${prefix}/workers`,
        update: (id) => `${prefix}/workers/${id}`,
        delete: (id) => `${prefix}/workers/${id}`
    },
    logs: {
        list: (appId) => `${prefix}/logs/${appId}`,
    },
    auth_app: {
        list: (limit, offset) => `${prefix}/auth/app`,
        update: (id) => `${prefix}/auth/app`,
        revokeOAuth2Token: (env, token) => `${prefix}/auth/app/${env}/${token}`,
    },
    cors: {
        list: (limit, offset) => `${prefix}/cors/list/${limit}/${offset}`,
        create: `${prefix}/cors`,
        update: (id) => `${prefix}/cors/${id}`,
        delete: (id) => `${prefix}/cors/${id}`
    },
    replicator: {
        list: (limit, offset) => `${prefix}/replicator/list/${limit}/${offset}`,
        update: () => `${prefix}/replicator`,
    },
    api: {
        // sandbox: `http://localhost:3001`,
        sandbox: `https://api.sandbox.simpletool.com.br`,
        // production: `https://api.simplerest.com.br`,
        sandbox_update: 'https://update.sandbox.simpletool.com.br',
        // sandbox_update: 'http://localhost:3005',

        // production_update: `https://update.simplerest.com.br`
    }
},
PERMISSIONS = {
    PERMISSION_DELETE: 0,
    PERMISSION_CREATE: 1,
    PERMISSION_EDIT: 2,
    PERMISSION_LIST: 3
}

export {
    URLS,
    PERMISSIONS
}