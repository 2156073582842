import React, { useEffect, useState } from 'react'
import { 
    Typography, 
    Space, 
    Button,
    Row,
    Col,
    Tooltip
} from 'antd'
import { useSWRConfig } from 'swr'
import {
    PERMISSIONS
} from '../../environment'
import { 
    Table,
    ConnectionModal,
    DestroyConnectionModal,
    MigrationLogsModal,
    Notification
} from '../../components'
import {
    PrincipalContainer
} from '../../containers'
import { Connections, Server } from '../../modules'
import { 
    DeleteFilled,
    EditFilled,
    PlayCircleFilled,
    InfoCircleOutlined,
    HistoryOutlined
} from '@ant-design/icons'

const { Link } = Typography

export default function ConnectionsPage ({ setCurrentPage, userPermissions }) {
    const { cache, mutate } = useSWRConfig()

    const [tableData, setTableData] = useState([]),
    [isLoading, setIsLoading] = useState(true),
    [editData, setEditData] = useState({}),
    [createData, setCreateData] = useState({}),
    [destroyData, setDestroyData] = useState({}),
    [connectionIdLog, setConnectionLogId] = useState(null),
    [openEditModal, setOpenEditModal] = useState(false),
    [openCreateModal, setOpenCreateModal] = useState(false),
    [openDestroyModal, setOpenDestroyModal] = useState(false),
    [openConnectionLogModal, setOpenConnectionLogModal] = useState(null),
    [serverIP, setServerIP] = useState('0.0.0.0'),
    tableColumns = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
        },
        {
            title: 'Ações',
            dataIndex: 'actions',
            key: '_id',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                <Space size="middle">
                    {userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && (
                        <Link onClick={() => handleEditModal(row)} href='#'>
                            <EditFilled  style={{ fontSize: '16px' }} />
                        </Link>
                    )}
                    {
                        <Link href='#' type='danger' onClick={() => handleDestroyModal(row)}>
                            <DeleteFilled style={{ fontSize: '16px', color: '#d9534f' }} />
                        </Link>
                    }
                    {/* {
                        (window.location.href.match(/sandbox/)?.length || window.location.href.match(/localhost/)?.length) && (
                            <Link onClick={async () => await handleTestConnection(row)} href='#'>
                                <PlayCircleFilled  style={{ fontSize: '16px' }} />
                            </Link>
                        )
                    } */}
                    {
                        <Link href='#' type='primary' onClick={() => handleLogsModal(row)}>
                            <HistoryOutlined style={{ fontSize: '16px' }} />
                        </Link>
                    }
                </Space>
            ),
        },
    ]

    const { data: connectionsData, isLoading: connectionsIsloading } = cache.get('@connections') || { data: [] }

    const mutating = async () => {
        await mutate('@connections', () => Connections.getClientConnections({}))
        await mutate('@server', () => Server.getServerIP())

        const newCache = cache.get('@connections')?.data ?? []

        setTableData(newCache?.map((conn, i) => {
            return {
                key: String(i),
                ...conn
            }
        }) || [])

        setEditData({})
        setDestroyData({})
        setCreateData({})

        setIsLoading(false)
    }

    const handleTestConnection = async (connection) => {
        if (connection?._id) {
            const testData = await Connections.testClientConnection({ id: connection?._id })

            if(testData?.status === 'fail') Notification({
                description: `Debug: ${testData.debug}`,
                title: 'Teste de Conexão',
                type: 'warning'
            })
            else Notification({
                description: `Debug: Simple REST conectado ao DB ${connection?.name.toUpperCase()} com sucesso`,
                title: 'Teste de Conexão',
            })
        }
    }

    const handleEditModal = (connection) => {
        setEditData({ ...connection })
    }

    const handleDestroyModal = (connection) => {
        setDestroyData({ ...connection })
    }

    const handleCreateModal = () => {
        setCreateData({ closer: 1 })
    }

    const handleLogsModal = (connection) => {
        setConnectionLogId(connection?._id)
    }

    const handleEditModalOpen = () => {
        return <ConnectionModal 
            title={`Editando conexão: ${editData?.name}`} 
            open={openEditModal} 
            handleOpen={setOpenEditModal} 
            connection={editData} 
            mutating={mutating}
        />
    }

    const handleCreateModalOpen = () => {
        return <ConnectionModal 
            title={`Criando nova conexão`} 
            open={openCreateModal} 
            handleOpen={setOpenCreateModal} 
            connection={{}} 
            mutating={mutating}
        />
    }

    const handleDestroyModalOpen = () => {
        return <DestroyConnectionModal 
            title={`Excluir conexão`} 
            open={openDestroyModal} 
            handleOpen={setOpenDestroyModal} 
            id={destroyData._id}
            name={destroyData.name} 
            mutating={mutating}
        />
    }

    const handleLogsModalOpen = () => {
        return <MigrationLogsModal 
            open={openConnectionLogModal} 
            handleOpen={setOpenConnectionLogModal} 
            connectionId={connectionIdLog}
            setConnectionId={setConnectionLogId}
        />
    }

    useEffect(() => {
        if(Object.keys(createData).length) setOpenCreateModal(true)
        else setOpenCreateModal(false)
    }, [createData])

    useEffect(() => {
        if(Object.keys(editData).length) {
            setCreateData({})
            setOpenEditModal(true)
        }
        else setOpenEditModal(false)
    }, [editData])

    useEffect(() => {
        if(Object.keys(destroyData).length) setOpenDestroyModal(true)
        else setOpenDestroyModal(false)
    }, [destroyData])

    useEffect(() => {
        if(connectionIdLog !== null) setOpenConnectionLogModal(true)
        else setOpenConnectionLogModal(false)
    }, [connectionIdLog])

    useEffect(() => {
        setTableData(connectionsData?.map((conn, i) => {
            return {
                key: String(i),
                ...conn
            }
        }) || [])
        setIsLoading(false)
    }, [connectionsIsloading])

    useEffect(() => {
        setServerIP(cache.get('@server')?.ip)
    }, [])

    return (
        <>
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && (
                    handleEditModalOpen()
                )
            }
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && (
                    handleCreateModalOpen()
                )
            }
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_DELETE) && (
                    handleDestroyModalOpen()
                )
            }
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_LIST) && (
                    handleLogsModalOpen()
                )
            }
            <PrincipalContainer menuItemSelected='0' setCurrentPage={setCurrentPage} content={
                <>
                    <Typography.Title level={2} style={{ textAlign: 'left' }}>
                        Conexões 
                        {   (window.location.href.match(/sandbox/)?.length || window.location.href.match(/localhost/)?.length) && (
                            <Tooltip placement="topLeft" title={`Liberar acesso do DB ao IP: ${serverIP}`}>
                                <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                            </Tooltip>
                        )
                        }
                        {userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && (
                            <Button style={{ float: 'right', top: 5 }} type="primary" shape="round" onClick={handleCreateModal}>+</Button>
                        )}
                    </Typography.Title>
                    <Row>
                        <Col span={24}>
                            <Table 
                                columns={tableColumns}
                                isLoading={isLoading}
                                tableData={tableData}
                            />
                        </Col>
                    </Row>
                </>
            } />
        </>
    )
}