import API from '../../services/api'
import { URLS } from '../../environment.js'
import { 
    verifyPermission, 
    permissionsStatus 
} from '../../helpers/permissions'

const getClientReplicator = async ({ limit = 500, offset = 0 }) => {
    if(!verifyPermission('replicator', permissionsStatus.list)) return []

    const response  = await API.get(URLS.replicator.list(limit, offset))

    return response?.data
}

const updateClientReplicator = async ({ replicatorData }) => {
    if(!verifyPermission('replicator', permissionsStatus.update)) return {}

    const { data: response, isError } = await API.put(URLS.replicator.update(), replicatorData)

    return response?.data || isError
}

const Replicator = {
    getClientReplicator,
    updateClientReplicator
}

export default Replicator