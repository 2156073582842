import React, { useEffect, useState } from "react"
import { 
    Modal,
    Input,
    Form,
    Select,
    Row,
    Tabs,
    Col
} from 'antd'
import { Notification } from "../.."
import { Connections } from "../../../modules"

const { Item } = Form

export default function ConnectionModal ({ title = '', open = false, handleOpen = () => {}, connection = {}, mutating = () => {} }) {
    const [conn, setConn] = useState({
        name: connection?.name || '',
        type: connection?.type || '',
        db_name: connection?.db_name || '',
        configurations: {
            dev: {
                host: '',
                port: '',
                user: '',
                password: '',
                db_name: ''
            },
            hml: {
                host: '',
                port: '',
                user: '',
                password: '',
                db_name: ''
            },
            sdb: {
                host: '',
                port: '',
                user: '',
                password: '',
                db_name: ''
            },
            prd: {
                host: '',
                port: '',
                user: '',
                password: '',
                db_name: ''
            },
        }
    }),
    [loading, setLoading] = useState(false),
    [currentEnvType, setCurrentEnvType] = useState('prd'),
    isEditModal = Object.keys(connection)?.length > 1 ? true : false

    const handleSave = async (envType) => {
        setLoading(true)
        
        let hasError = false

        if(isEditModal) {
            let configurations = {}

            Object.keys(conn?.configurations).forEach(env => {
                const confValues = conn?.configurations?.[env]

                if(confValues.host?.length > 0) {
                    Object.assign(configurations, {
                        [env]: confValues
                    })
                }
            })

            const res = await Connections.updateClientConnection({ connection: {
                name: conn.name,
                type: conn.type,
                db_name: conn.db_name,
                configurations
            }, id: connection?._id })

            hasError = res == true || false
        } else {
            const res = await Connections.createClientConnection({ connection: conn })

            hasError = res == true || false
        }

        setLoading(false)

        if(hasError) return
        
        handleOpen(false)
        handleNotificationSuccess()
        await mutating()
    }

    const handleNotificationSuccess = () => {
        const modalType = isEditModal ? 'editada' : 'criada'
        
        Notification({ 
            description: `Conexão ${modalType} com sucesso`,
            title: 'Conexões'
        })
    }

    const handleValue = (e, key, type = null, isDelete = false) => {
        const splitedKeys = key.split('.'),
            hasMoreThanOneKey = splitedKeys.length > 1

        const value = e?.target?.value

        if(hasMoreThanOneKey) {
            let tmpValue = null

            splitedKeys.forEach((key, i) => {
                if(!i) {
                    tmpValue = conn[key]
                } else if (i !== (splitedKeys.length - 1)) {
                    tmpValue = tmpValue[key]
                }
            })

            if(type && !isDelete) {
                if(!value || isDelete) {
                    tmpValue[splitedKeys[splitedKeys.length - 1]] = []
                } else {
                    tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                }
            } else {
                tmpValue[splitedKeys[splitedKeys.length - 1]] = value
            }
        } else {
            conn[key] = value
        }

        setConn({ ...conn })
    }

    useEffect(() => {
        if(Object.keys(connection)?.length) {
            setConn({
                name: connection?.name || '',
                type: connection?.type || '',
                db_name: connection?.db_name || '',
                configurations: connection?.configurations || {
                    dev: {
                        host: '',
                        port: '',
                        user: '',
                        password: '',
                    },
                    hml: {
                        host: '',
                        port: '',
                        user: '',
                        password: '',
                    },
                    sdb: {
                        host: '',
                        port: '',
                        user: '',
                        password: '',
                    },
                    prd: {
                        host: '',
                        port: '',
                        user: '',
                        password: '',
                    },
                }
            })
        } else {
            setConn({
                name: '',
                type: '',
                db_name: '',
                configurations: {
                    dev: {
                        host: '',
                        port: '',
                        user: '',
                        password: '',
                    },
                    hml: {
                        host: '',
                        port: '',
                        user: '',
                        password: '',
                    },
                    sdb: {
                        host: '',
                        port: '',
                        user: '',
                        password: '',
                    },
                    prd: {
                        host: '',
                        port: '',
                        user: '',
                        password: '',
                    },
                }
            })
        }
        setLoading(false)
    }, [connection])
    
    const getForm = (envType) => {
        return (
            <>
                <Row 
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col className="gutter-row" span={8}>
                        <Item label="Host">
                            <Input placeholder="1234a.exemplo.com" onChange={(e) => handleValue(e, `configurations.${envType}.host`)} value={conn?.configurations?.[envType]?.host} />
                        </Item>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Item label="Porta">
                            <Input placeholder="3306" onChange={(e) => handleValue(e, `configurations.${envType}.port`)} value={conn?.configurations?.[envType]?.port} />
                        </Item>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Item label="Usuário">
                            <Input placeholder="root" onChange={(e) => handleValue(e, `configurations.${envType}.user`)} value={conn?.configurations?.[envType]?.user} />
                        </Item>
                    </Col>
                </Row>
                <Row 
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col className="gutter-row" span={24}>
                        <Item label="Senha">
                            <Input.Password placeholder="1234" onChange={(e) => handleValue(e, `configurations.${envType}.password`)} value={conn?.configurations?.[envType]?.password} />
                        </Item>
                    </Col>
                </Row>
                <h5>
                    * no Sandbox configure apenas o ambiente de "Desenvolvimento" para teste
                </h5>
            </>
        )
    }

    const tabsEnv = [
        <>
            <h3 style={{ textAlign: 'left' }}>
                Produção
            </h3>
            {getForm('prd')}
           
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Homologação
            </h3>
            {getForm('hml')}
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Sandbox
            </h3>
            {getForm('sdb')}
        </>,
        <>
             <h3 style={{ textAlign: 'left' }}>
                Desenvolvimento
            </h3>
            {getForm('dev')}
        </>
    ]

    return (
        <Modal
            title={title}
            centered
            open={open}
            onCancel={async () => {
                handleOpen(false)
            }}
            width={1000}
            onOk={async () => await handleSave(currentEnvType)}
            okText="Salvar"
            cancelText="Cancelar"
            cancelButtonProps={{ type: 'text' }}
            okButtonProps={{ loading: loading }}
        >
            <Row 
                gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}
            >
                <Col span={24}>
                    <Form 
                        layout={'vertical'}
                        initialValues={{
                            ...conn
                        }}
                    >
                        <Row 
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                        >
                            <Col className="gutter-row" span={24}>
                                <Item label="Nome">
                                    <Input placeholder="Nome da conexão" onChange={(e) => handleValue(e, 'name')} value={conn?.name} />
                                </Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Item label="Tipo DB">
                                    <Select
                                        value={conn?.type}
                                        onChange={(e) => handleValue({ target: { value: e } }, `type`)}
                                        options={[
                                            {
                                                value: 'mysql',
                                                label: 'MySQL/MariaDB',
                                            },
                                            {
                                                value: 'pg',
                                                label: 'PostgresSQL',
                                            },
                                            {
                                                value: 'mssql',
                                                label: 'SQL Server',
                                            }
                                        ]}
                                    />
                                </Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Item label="Nome do DB">
                                    <Input placeholder="exemplo" onChange={(e) => handleValue(e, `db_name`)} value={conn?.db_name} />
                                </Item>
                            </Col>
                        </Row>
                        <h4 style={{ textAlign: 'left' }}>Configurações</h4>
                        <Tabs
                            defaultActiveKey="3"
                            items={['Produção', 'Homologação', 'Sandbox', 'Desenvolvimento'].map((tabName, i) => {
                                return {
                                    label: (
                                    <span>
                                        {tabName}
                                    </span>
                                    ),
                                    key: i + 1,
                                    children: tabsEnv[i],
                                }
                            })}
                            onTabClick={(tabKey) => {
                                let envType = 'dev'

                                switch (tabKey) {
                                    case 1:
                                        envType = 'prd'
                                        break
                                    case 2:
                                        envType = 'hml'
                                        break
                                    case 3:
                                        envType = 'sdb'
                                        break
                                    case 4:
                                        envType = 'dev'
                                        break
                                }

                                setCurrentEnvType(envType)
                            }}
                        />
                    </Form>
                </Col>
            </Row>
        </Modal>
    )
} 