import React, { useEffect, useState } from 'react'
import { 
    Typography, 
    Select, 
    Button,
    Row,
    Col,
    Tooltip,
    Form,
    Input,
    Collapse,
    Divider,
    Switch,
    Tabs,
    Popconfirm
} from 'antd'
import { 
    InfoCircleOutlined,
    CheckOutlined,
    CloseOutlined,
    KeyOutlined
} from '@ant-design/icons'
import { useSWRConfig } from 'swr'
import {
    PERMISSIONS
} from '../../environment'
import { 
    Notification
} from '../../components'
import {
    PrincipalContainer
} from '../../containers'
import { AuthApp } from '../../modules'
import { Editor } from '@monaco-editor/react'

const { Item } = Form

export default function AuthPage ({ setCurrentPage, userPermissions }) {
    const { cache, mutate } = useSWRConfig()

    const [isLoading, setIsLoading] = useState(true),
        [editData, setEditData] = useState({}),
        [tabs, setTabs] = useState([])

    const { data: authAppData, isLoading: authAppIsloading } = cache.get('@authApp') || { data: [] }
    const { data: connectionsData, isLoading: connectionsIsloading } = cache.get('@connections') || { data: [] }

    const handleSave = async () => {
            await AuthApp.updateClientAuthApp({ authApp: editData, id: editData?._id })

            await mutating()

            return Notification({
                title: 'Configurações de Autorização atualizadas com sucesso!'
            })
    }

    const mutating = async () => {
        await mutate('@authApp', () => AuthApp.getClientAuthApp({}))

        const newCache = cache.get('@authApp')?.data ?? []

        if(newCache?.length) {
            const authApp = newCache?.[0] || {}

            setEditData({ ...authApp })
        }

        setIsLoading(false)
    }

    const handleRevokeToken = async (env, index, token) => {
        await AuthApp.revokeOAuth2Token({ env, token }).then(async res => {
            if(!res?.response_data) {
                return handleErrorOnRevokeToken()
            }

            editData.oauth2_tokens[env] = editData.oauth2_tokens[env].filter((_, i) => i !== index)
    
            setEditData({ ...editData })
            await mutating()
        }).catch(err => {
            handleErrorOnRevokeToken()
        })
    }

    const handleErrorOnRevokeToken = () => {
        return Notification({ 
            description: `Não foi possível revogar o token, tente novamente em instantes`,
            title: 'Revogar Token',
            type: 'error'
        })
    }

    const handleValue = (e, key, type = null, isDelete = false) => {
        const splitedKeys = key.split('.'),
            hasMoreThanOneKey = splitedKeys.length > 1

        const value = e?.target?.value

        if(hasMoreThanOneKey) {
            let tmpValue = null

            splitedKeys.forEach((key, i) => {
                if(!i) {
                    tmpValue = editData[key]
                } else if (i !== (splitedKeys.length - 1)) {
                    tmpValue = tmpValue[key]
                }
            })

            if(type && !isDelete) {
                const r = new RegExp(`array.string.\*`, 'g')

                if(type.match(r)?.length) {
                    const index = parseInt(type.split('.')[2])
                    let hasIndex = typeof tmpValue[splitedKeys[splitedKeys.length - 1]][index] == 'string'

                    if(!hasIndex) tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                    else {
                        let newValue = hasIndex ? tmpValue[splitedKeys[splitedKeys.length - 1]][index] : ''
                        
                        newValue = !value ? '': value
    
                        tmpValue[splitedKeys[splitedKeys.length - 1]][index] = newValue
                    }
                } else if(!value || isDelete) {
                    tmpValue[splitedKeys[splitedKeys.length - 1]] = []
                } else {
                    tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                }
            } else {
                tmpValue[splitedKeys[splitedKeys.length - 1]] = value
            }
        } else {
            editData[key] = value
        }

        setEditData({ ...editData })
    }

    const mountCollapses = () => {
        const panels = [
            {
                title: 'Configurações',
                titleDesc: 'Configurações de autorização',
                children: () => {
                    return (
                        <>
                            <Form 
                                layout={'vertical'}
                            >
                                <Typography.Title level={4} style={{ textAlign: 'left' }}>Autorização JWT</Typography.Title>
                                <Typography.Paragraph level={5} style={{ textAlign: 'left' }}>* Esse é o modo automático de autorização JWT do SimpleREST, caso seu sistema não tenha autenticação... caso seu sistema já possua autenticação, utilizar o "Reverse Authorization"</Typography.Paragraph>
                                <Row 
                                    gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                    }}
                                >
                                    <Col className="gutter-row" span={6}>
                                        <Item label="Conexão">
                                            <Select
                                                value={editData?.connection_id}
                                                loading={connectionsIsloading}
                                                onChange={(e) => handleValue({ target: { value: e } }, `connection_id`)}
                                                options={connectionsData.map(conn => {
                                                    return {
                                                        label: conn.name,
                                                        value: conn._id
                                                    }
                                                })}
                                            />
                                        </Item>
                                    </Col>
                                    <Col className="gutter-row" span={6}>
                                        <Item label="Tabela">
                                            <Input placeholder="Nome da tabela de usuários" onChange={(e) => handleValue(e, `table`)} value={editData?.table}  />
                                        </Item>
                                    </Col>
                                    <Col className="gutter-row" span={6}>
                                        <Item label="Coluna de email">
                                            <Input title="Coluna que pesquisa se o usuario existe" placeholder="Coluna que pesquisa se o usuario existe" onChange={(e) => handleValue(e, `search_key`)} value={editData?.search_key}  />
                                        </Item>
                                    </Col>
                                    <Col className="gutter-row" span={6}>
                                        <Item label="Coluna de senha">
                                            <Input title="Coluna que compara hash de senha" placeholder="Coluna que compara hash de senha" onChange={(e) => handleValue(e, `pass_compare_key`)} value={editData?.pass_compare_key}  />
                                        </Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Item label="Criptografia da Senha">
                                            <Select
                                                value={editData?.hash_type}
                                                loading={connectionsIsloading}
                                                onChange={(e) => handleValue({ target: { value: e } }, `hash_type`)}
                                                options={[
                                                    {
                                                        label: 'SHA512',
                                                        value: 'SHA512'
                                                    },
                                                    {
                                                        label: 'SHA256',
                                                        value: 'SHA256'
                                                    }
                                                ]}
                                            />
                                        </Item>
                                    </Col>
                                </Row>
                                <Divider></Divider>
                                <Typography.Title level={4} style={{ textAlign: 'left' }}>Status</Typography.Title>
                                <Row 
                                    gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                    }}
                                >
                                    <Col className="gutter-row" span={4}>
                                        <Item label="Reverse Authorization">
                                            <Tooltip placement="topLeft" title="Permite fazer autorização reversa através do token">
                                                <Switch 
                                                    style={{
                                                        float: 'left'
                                                    }}
                                                    onChange={(e) => handleValue({ target: { value: !editData?.reverse_authorization ? true : false } }, `reverse_authorization`)}
                                                    checkedChildren={<CheckOutlined />}
                                                    unCheckedChildren={<CloseOutlined />}
                                                    checked={editData?.reverse_authorization}
                                                />
                                            </Tooltip>
                                        </Item>
                                    </Col>
                                    <Col className="gutter-row" span={16}>
                                        <Item label="Reverse Authorization Token">
                                            <Input placeholder="reverse auth token" onChange={(e) => handleValue(e, `reverse_authorization_token`)} value={editData?.reverse_authorization_token}  />
                                        </Item>
                                    </Col>
                                    <Col className="gutter-row" span={4}>
                                        <Item label="OAuth 2">
                                            <Switch 
                                                style={{
                                                    float: 'left'
                                                }}
                                                onChange={(e) => handleValue({ target: { value: !editData?.oauth2_authentication ? true : false } }, `oauth2_authentication`)}
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                                checked={editData?.oauth2_authentication}
                                            />
                                        </Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Item label="Tempo de sessão">
                                            <Input placeholder="em segundos" style={{ textAlign: 'center' }} type={'number'} onChange={(e) => handleValue(e, `session_expires`)} value={editData?.session_expires}  />
                                        </Item>
                                    </Col>
                                </Row>
                                <Row 
                                    gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                    }}
                                >
                                    <Col className="gutter-row" span={24}>
                                        {/* {userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && ( */}
                                            <Button style={{ float: 'right' }} type="primary" onClick={handleSave}>Salvar</Button>
                                        {/* )} */}
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )
                }
            },
            // {
            //     title: 'Sessões OAuth2 ativas',
            //     titleDesc: '',
            //     children: () => {
            //         return (
            //             <>
            //                 <Form 
            //                     layout={'vertical'}
            //                 >
            //                     <Row 
            //                         gutter={{
            //                             xs: 8,
            //                             sm: 16,
            //                             md: 24,
            //                             lg: 32,
            //                         }}
            //                     >
            //                         <Col className="gutter-row" span={24}>
            //                             <Tabs 
            //                                 tabPosition={'top'}
            //                                 items={tabs.map((tab, i) => {
            //                                     const id = String(i);
            //                                     return {
            //                                         label: tab.name,
            //                                         key: id,
            //                                         children: <Collapse>
            //                                             {tab.content}
            //                                         </Collapse>
            //                                     }
            //                                 })}
            //                             />
            //                         </Col>
            //                     </Row>
            //                 </Form>
            //             </>
            //         )
            //     }
            // }
        ]

        return panels.map(panel => <Collapse.Panel header={
            <>
                <Typography.Title level={5} style={{ textAlign: 'left', marginTop: 3 }}>
                    {panel.title}
                    <Tooltip placement="topLeft" title={panel.titleDesc}>
                        <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                    </Tooltip>    
                </Typography.Title>
            </>
        }>
            {panel.children()}
        </Collapse.Panel>)
    }

    useEffect(() => {
        const authApp = authAppData || {
            'connection_id': null,
            'session_expires': 3600,
            'oauth2_authentication': false,
            'reverse_authorization': false,
            'reverse_authorization_token': '',
            'table': '',
            'hash_type': '',
            'search_key': '',
            'pass_compare_key': '',
            'oauth2_tokens': {
                'dev': [],
                'sdb': [],
                'hml': [],
                'prd': [],
            }
        }

        setEditData({ ...authApp })

        setIsLoading(false)
    }, [authAppData])

    useEffect(() => {
        setTabs([])
    }, [editData])

    useEffect(() => {
        if(!tabs.length) {
            Object.keys(editData?.oauth2_tokens || {}).forEach((envKey, tabIndex) => {
                const tokens = editData?.oauth2_tokens[envKey]
    
                if(tabs.length < 4) {
                    let name = ''
        
                    switch (envKey) {
                        case 'dev':
                            name = 'Desenvolvimento'
                            break
                        case 'sdb':
                            name = 'Sandbox'
                            break
                        case 'hml':
                            name = 'Homologação'
                            break
                        case 'prd':
                            name = 'Produção'
                            break
                    }
        
                    tabs.push({
                        name,
                        content: []
                    })
                }
    
                tokens.forEach((oauth2Session, i) => {
                    let token = oauth2Session.token
    
                    tabs[tabIndex].content.push(
                        <Collapse.Panel header={<Typography.Title level={5} style={{ textAlign: 'left', marginTop: 3 }}>{token}</Typography.Title>}>
                            <Row 
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}
                            >
                                <Col className="gutter-row" span={12}>
                                    <Input placeholder="" disabled value={token} />
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <Editor 
                                        language='json'
                                        value={JSON.stringify(oauth2Session.data ?? '{}', null, 4)}
                                        theme='vs-dark'
                                        height="50vh" 
                                        options={{
                                            autoIndent: "full",
                                            readOnly: true
                                        }}
                                    />
                                    <Popconfirm
                                        title="Revogar token"
                                        description={`Tem certeza que deseja revogar o token (${token})? Essa ação é irreversível.`}
                                        onConfirm={async () => await handleRevokeToken(envKey, i, token)}
                                        okText="Sim"
                                        cancelText="Não"
                                        okButtonProps={{
                                            danger: true
                                        }}
                                    >
                                        <Button style={{ float: 'right', top: 5 }} icon={<KeyOutlined />} type={'primary'} danger onClick={() => {}}>Revogar</Button>
                                    </Popconfirm>
                                </Col>
                            </Row>
                        </Collapse.Panel>
                    )
                })
            })
    
            setTabs([ ...tabs ])
        }
    }, [tabs])

    return (
        <>
            <PrincipalContainer menuItemSelected='18' setCurrentPage={setCurrentPage} content={
                <div>
                    <Typography.Title level={2} style={{ textAlign: 'left' }}>
                        Autorização 
                        <Tooltip placement="topLeft" title={`Defina o padrão de autorização de acesso às suas APIs`}>
                            <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                        </Tooltip>
                    </Typography.Title>
                    {userPermissions?.includes(PERMISSIONS.PERMISSION_LIST) && (
                        <Row>
                            <Col span={24}>
                                <Collapse
                                    accordion={true}
                                    bordered={false}
                                >
                                    {mountCollapses()}
                                </Collapse> 
                            </Col>
                        </Row>
                    )}
                </div>
            } />
        </>
    )
}